import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dateRange", "chartContainer", "loader", "sourceType"]

  connect() {
    this.initChart()
    this.fetchChartDataForRange()
  }

  initChart() {
    var chartData = this.createChartData()
    this.chart = new Chart(this.chartContainerTarget, {
      type: "bar",
      data: chartData,
      options: {
        "scales": {
          "yAxes": [{
            "ticks": {
              "beginAtZero": true
            }
          }]
        }
      },
      plugins: {},
    })
  }

  createChartData(payload = { labels: [], data: [] }) {
    if (payload.chart_type && payload.chart_type == "bar") {
      return {
        "labels": payload.labels,
        "datasets": [{
          "label": this.data.get("label"),
          "backgroundColor": "#6777ef",
          "borderColor": "#6777ef",
          "data": payload.data
        }]
      }
    } else {
      return {
        "labels": payload.labels,
        "datasets": [{
          "type": "line",
          "label": this.data.get("label"),
          "backgroundColor": "#6777ef",
          "borderColor": "#6777ef",
          "data": payload.data,
          "borderWidth": 1.0,
          "pointBackgroundColor": "#ffffff",
          "pointRadius": this.getPointRadius(payload)
        }]
      }
    }
    
  }

  getPointRadius(payload) {
    var radius_arr = []
    var p95_data = payload.p95_data

    for (var i = 0,len = payload.data.length; i < len; i++) {
      var pointLabel = payload.labels[i]

      if (p95_data) {
        var flag = true
        for (var j = 0,lenJ = p95_data.length; j < lenJ; j++) {
          var label = p95_data[j][0]
          var value = p95_data[j][1]
          if (value == payload.data[i] && label == pointLabel) {
            radius_arr.push(6)
            flag = false
          }
        }

        if (flag) {
          radius_arr.push(0)
        }
      } else {
        radius_arr.push(0)
      }

    }

    return radius_arr
  }

  rangeSelected(event) {
    event.preventDefault()

    const selectedDateRange = event.currentTarget.dataset.dateRange
    this.dateRangeTargets.forEach((el) => {
      let elDateRange = el.dataset.dateRange
      el.classList.toggle("active", elDateRange == selectedDateRange)
    })
    this.fetchChartDataForRange()
  }

  fetchChartDataForRange() {
    var range = ""
    this.dateRangeTargets.forEach((el) => {
      if (el.classList.length == 2) {
        range = el.dataset.dateRange
      }
    })

    var source = ""
    if (this.hasSourceTypeTarget) {
      source = this.sourceTypeTarget.dataset.current
    }

    $(this.chartContainerTarget).hide()
    $(this.loaderTarget).show()
    const dataUrl = this.data.get("dataUrl")
    const that = this
    $.ajax({
      method: "GET",
      url: dataUrl,
      data: {
        date_range: range,
        data_source: source
      }
    })
    .done(function(payload) {
      that.showChart(payload)
    })
  }

  showChart(payload) {
    $(this.loaderTarget).hide()
    var chartData = this.createChartData(payload)
    this.chart.data = chartData
    this.chart.update()
    $(this.chartContainerTarget).show()
  }

  sourceTypeSelected(event) {
    event.preventDefault()
    this.sourceTypeTarget.innerHTML = event.currentTarget.innerHTML;
    var current = event.currentTarget.dataset.source;
    this.sourceTypeTarget.dataset.current = current;

    this.fetchChartDataForRange()
  }

}
